/* App.css */

  
  .chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* 화면 전체 너비 */
    padding: 5px;
    box-sizing: border-box;
  }
  
  .chat-box {
    width: 100%;
    flex: 1; /* 채팅 메시지 영역을 가능한 공간만큼 확장 */
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .message {
    margin-bottom: 8px;
    white-space: pre-wrap; /* 코드와 텍스트 모두 줄 바꿈을 유지 */
  }
  
  .input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  
  .input-container > .p-inputtext {
    flex: 1; /* 텍스트 입력란이 공간을 채울 수 있도록 */
  }
  
  input, textarea {
    font-size: 14px;
  }
  