/* 페이지 전체를 중앙으로 정렬 */
body, html {
	padding: 10px;
	margin: 0;
	font-family: Arial, sans-serif;
	/* display: flex; */
	/* justify-content: center; */
	/* align-items: center; */
	background-color: #f0f2f5;
}

/* 로그인 폼을 감싸는 div */
.login-container {
	display: flex;
	/* justify-content: center; */
	/* align-items: center; */
	/* width: 100%; */
}

/* Card 컴포넌트 스타일 */
.p-card {
	width: 100%;
	/* max-width: 400px; */
	margin: 20px;
}

/* label과 input 항목의 크기 동일하게 설정 */
.p-field {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
}

.p-field label {
	font-size: 14px;
}

label {
	padding-left: 5px;
	padding-bottom: 5px;
}

.input-field {
	width: 100%;
	height: 40px;
	/* 높이 동일하게 설정 */
	padding: 0.5rem;
	/* 내부 여백 */
	font-size: 1rem;
	/* 글자 크기 */
	box-sizing: border-box;
	/* padding 포함하여 크기 설정 */
}

/* 로그인 버튼 크기 동일하게 설정 */
.p-button-sm {
	width: 100%;
	height: 40px;
	/* 버튼 높이 동일하게 설정 */
}

/* 비밀번호 필드의 스타일 */
.p-password input {
	width: 100%;
	height: 43px;
	/* 비밀번호 입력 필드의 높이 통일 */
	padding: 0.5rem;
	/* 내부 여백 */
	font-size: 1rem;
	/* 글자 크기 */
}


input::placeholder {
	font-size: 14px;
	/* 원하는 폰트 크기 설정 */
}